<template>
	<div class="spinner" :style="style" :class="classes"></div>
</template>

<script>
	export default {
		name: 'spinner',
		computed:{
			classes(){
				return [
					(typeof this.colors !== 'undefined') ? 'color-'+this.colors : 'color-blue-base',
				]
			},
			style(){
				let string = '';
				if(typeof this.size !== 'undefined')
					string += 'width:'+this.size+'px;height:'+this.size+'px;';
				return string;
			}
		},
		props: {
			colors: String,
			size: Number,
		}
	}
</script>


<style lang="scss" scoped>
	.spinner {
		border-radius: 50%;
		width: 30px;
		height: 30px;
		animation: spin 1000ms linear infinite;
		border-width: 3px;
		border-style: solid;
		margin: 0 auto;
		border-color: #9a9a9a;
		border-top-color: #fff;
		
	}
	
	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
</style>
