<template>
	<div class="standard-button" :class="classes">
		<div v-if="loaderActive" class="loader">
			<spinner :size="18" :colors="spinnerColor" />
		</div>
		<div class="text" :class="{hidden: loaderActive}">{{text}}</div>
		<div v-if="showRightIcon" class="icon-right">
			<slot name="icon-right"/>
		</div>
	</div>
</template>

<script>
	import Spinner from "@/components/loaders/Spinner";
	export default {
		name: "StandardButton",
		components: {Spinner},
		computed: {
			classes(){
				return [
					"btn" ,
					"btn-secondary",
					this.useDefaultSizes === false ? 'custom-sizes' : '',
					this.useDefaultColors === false ? 'custom-colors' : '',
					this.showRightIcon ? 'show-right-icon' : ''
					
				]
			},
			spinnerColor(){
				if(this.type === 'success')
					return 'green-darkest';
				
				return 'blue-darkest';
			}
		},
		props:{
			text: String,
			loaderActive: Boolean,
			disabled: {
				type: Boolean,
				default: false
			},
			useDefaultSizes: {
				type: Boolean,
				default: false
			},
			useDefaultColors: {
				type: Boolean,
				default: false
			},
			showRightIcon: {
				type: Boolean,
				default: false
			}
		}
	};
</script>

<style lang="scss" scoped>
	.standard-button{
		position: relative;
		.custom-colors{
			background-color: #444;
			color: #fff;
		}
		&.custom-sizes{
			padding: 5px 30px;
			font-size: 14px;
		}
		&.show-right-icon{
			padding-right: 40px;
			padding-left: 40px;
		}
		.text{
			display: inline-block;
			&.hidden{
				visibility: hidden;
			}
		}
		.icon-right{
			position: absolute;
			top:50%;
			right: 14px;
			transform: translateY(-50%);
		}
		.loader{
			position: absolute;
			top:50%;
			left: 50%;
			transform: translate(-50%,-50%);
			.spinner{
				margin: 3px auto 0;
			}
		}
	}
</style>
