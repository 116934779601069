<template>
    <div class="box px-4 py-4 mt-4" >
        <div class="row">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FormContainer'
    }
</script>

<style lang="scss" scoped>

</style>
