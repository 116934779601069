<template>
	<div class="reset-password pt-4">
		<h3>{{$t(localeArea+'.title')}}</h3>
		<form-container >
			<p class="col-12">{{$t(localeArea+'.helpText')}}</p>
			<field-input v-model="password1" classes="col-6" :error="password1Error" type="password" :label="$t('labels.password')" />
			<field-input v-model="password2" classes="col-6" :error="password2Error" type="password" :label="$t('labels.repeatPassword')" />
			<div class="col-12 pt-2">
				<standard-button :loader-active="loading" :text="$t(localeArea+'.change')" @click.native="attemptChangePassword" />
			</div>
		</form-container>
	</div>
</template>

<script>
	import FormContainer from "@/components/container/FormContainer";
	import FieldInput from "@/components/inputs/FieldInput";
	import StandardButton from "@/components/buttons/StandardButton";
	export default {
		name: "ResetPassword",
		components: {StandardButton, FieldInput, FormContainer},
		data(){
			return {
				password1: '',
				password2: '',
				password1Error: '',
				password2Error: '',
				loading: false,
			}
		},
		computed: {
			isDefine(){
				return typeof window.location.href !== 'undefined' && window.location.href.indexOf('?definePassword') !== -1;
			},
			localeArea(){
				return this.isDefine ? 'definePassword' : 'resetPassword';
			}
		},
		methods: {
			attemptChangePassword(){
				if(this.loading === false)
				{
					this.password1Error = '';
					this.password2Error = '';
					if(this.password1 !== this.password2)
					{
						this.password1Error = this.$t('errors.passwordsMustMatch');
						this.password2Error = this.$t('errors.passwordsMustMatch');
					}
					else if(this.password1.length < 6)
					{
						this.password1Error = this.$t('errors.tooShort');
						this.password2Error = this.$t('errors.tooShort');
					}
					else
					{
						this.loading = true;
						this.$concreteApi.post(undefined,'/account/token-password-change',{password:this.password1,user:this.$route.params.user,token:this.$route.params.token},true)
						.then(response => {
							if(typeof response.changed !== 'undefined' && response.changed === true)
							{
								this.$swal.fire({
									icon: 'success',
									title: this.$t("terms.success"),
									text: this.$t(this.localeArea+".passwordChanged"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								}).then((result) => {
									this.$router.push({name:'login',params: {lang: this.$i18n.locale}});
								});
							}
							else
							{
								if(typeof response.reason !== 'undefined')
								{
									this.$swal.fire({
										icon: 'error',
										title: this.$t("terms.error"),
										text: this.$t("errors."+response.reason),
										confirmButtonText: this.$t("terms.ok"),
										confirmButtonColor: "#495760"
									});
								}
								else
								{
									this.fireGenericError();
								}
							}
						})
						.catch(error => {
							console.log(error)
							this.fireGenericError();
						})
						.then(() => {
							this.loading = false;
						},() => {
							this.loading = false;
						})
					}
				}
			}
		}
	};
</script>

<style scoped>

</style>
